<template>
  <div class="">
    <div class="flex justify-space-between">
      <div class="box self-flex-end"><h2 class="mv-0">TEMPLATES</h2></div>
      <div class="box"><el-button @click="$onCommandParams('calendar')">BACK</el-button></div>
    </div>
    <el-divider class="mv-10 o-020"></el-divider>

    <el-tabs tab-position="left" v-model="active">
      <el-tab-pane :label="template.name" v-for="(template, index) in templates" :key="template._id" :name="index.toString()">
        <app-template
          :template="template"
          :teplateIndex="index"
          @createTemplate="createTemplate"
          @removeTemplate="removeTemplate"
          ></app-template>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AppTemplate from './Template'

export default {
  name: 'Templates',

  components: {
    AppTemplate
  },

  data () {
    return {
      active: '0'
    }
  },

  computed: {
    ...mapState({
      templates: state => state.templates.templates
    })
  },

  methods: {
    createTemplate () {
      this.$nextTick(() => {
        this.active = (this.templates.length - 1).toString()
      })
    },
    removeTemplate () {
      this.$nextTick(() => {
        this.active = '0'
      })
    }
  }
}
</script>

<style lang="scss">
</style>
