<template>
    <div>
        <div class="flex gaps column">
            <div class="box flex justify-space-between">
                <div class="box template-cell" v-bind:style="{ backgroundColor: dataTemplate.color}">
                    <div class="m-8">{{dataTemplate.name}}</div>
                </div>
                <div class="box self-flex-end">
                    <el-button size="medium" @click="edit">Save</el-button>
                    <el-button size="medium" @click="create">Create new template</el-button>
                    <el-button size="medium"  @click="removeTemplate" type="danger" icon="el-icon-delete">Remove template</el-button>
                </div>
            </div>

            <div class="box flex gaps mt-10">
                <el-input v-model="dataTemplate.name"></el-input>
                <el-color-picker v-model="dataTemplate.color" :predefine="predefineColors"></el-color-picker>
                <el-button @click="addRow">Add Session</el-button>
            </div>

        </div>
        <el-table
            class="table-template"
            :data="dataTemplate.sessions"
            style="width: 100%">
            <el-table-column
                type="index">
            </el-table-column>
            <el-table-column
                label="Start">
                <template #default="scope">
                    <el-input size="small"
                        style="text-align:center"
                        v-model="scope.row.start"></el-input>
                </template>
            </el-table-column>
            <el-table-column
                label="End">
                <template #default="scope">
                    <el-input size="small"
                        style="text-align:center"
                        v-model="scope.row.end"></el-input>
                </template>
            </el-table-column>
            <el-table-column
                label="capacity">
                <template #default="scope">
                    <el-input size="small"
                        style="text-align:center"
                        v-model="scope.row.capacity"></el-input>
                </template>
            </el-table-column>
            <el-table-column
                label="price" width="150">
                <template #default="scope">
                    <el-input size="small"
                        style="text-align:center"
                        v-model="scope.row.price"></el-input>
                </template>
            </el-table-column>
            <el-table-column
            width="65"
                label="event">
                <template #default="scope">
                    <el-checkbox size="small"
                        style="text-align:center"
                        v-model="scope.row.isEvent"></el-checkbox>
                </template>
            </el-table-column>
            <el-table-column
                label="Name">
                <template #default="scope">
                  <el-select size="small" v-model="scope.row.sessionId">
                    <el-option
                      v-for="item in sessionsForSelect"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </template>
            </el-table-column>
            <el-table-column>
                <template slot-scope="scope">
                    <el-button @click.native.prevent="deleteRow(scope.$index, scope.row)" type="text" size="small">
                        Delete
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <p class="hint-text p-20">* if marked, the session would have a reference note and description would be below.</p>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Templates',

  props: {
    template: {
      type: Object,
      default () {
        return {}
      },
      required: true
    },
    teplateIndex: {
      type: Number,
      default () {
        return -1
      },
      required: true
    }
  },

  data () {
    return {
      dataTemplate: {
        name: '',
        color: '',
        capacity: 0,
        sessions: []
      },
      predefineColors: [
        '#eb2f5b',
        '#5f8fdf',
        '#232933',
        '#52f17e',
        '#e2444a',
        '#993264',
        '#783189',
        '#402e84'
      ]
    }
  },

  computed: {
    ...mapGetters([
      'sessionsForSelect'
    ])
  },

  methods: {
    addRow () {
      let newRow = {
        price: '105, 112, 95',
        start: '00:00',
        end: '00:00',
        capacity: 90,
        isEvent: false,
        sessionId: this.sessionsForSelect.length > 0 ? this.sessionsForSelect[0].value : ''
      }
      this.dataTemplate.sessions = [...this.dataTemplate.sessions, newRow]
    },
    edit () {
      this.$store.dispatch('saveTemplate', {
        id: this.template._id,
        content: {
          name: this.dataTemplate.name,
          color: this.dataTemplate.color,
          sessions: this.dataTemplate.sessions,
          capacity: this.dataTemplate.capacity
        }
      })
        .then(() => {
          this.$message({
            showClose: true,
            message: 'Template edited successfully',
            type: 'success'
          })
        })
    },
    create () {
      let model = Object.assign({}, this.dataTemplate, { name: 'New template', color: '#402e84' })
      this.$store.dispatch('createTemplate', model)
        .then(() => {
          this.$message({
            showClose: true,
            message: 'Template created successfully',
            type: 'success'
          })
        })
        .then(() => {
          this.$emit('createTemplate')
        })
        .catch((e) => {
        })
    },
    deleteRow (index) {
      this.dataTemplate.sessions.splice(index, 1)
    },
    removeTemplate () {
      this.$store.dispatch('removeTemplate', this.template._id)
        .then(() => {
          this.$message({
            showClose: true,
            message: 'Template removed successfully',
            type: 'success'
          })
        })
        .then(() => {
          this.$emit('removeTemplate')
        })
        .catch((e) => {
        })
    }
  },
  mounted () {
    this.dataTemplate.name = this.template.name
    this.dataTemplate.color = this.template.color
    this.dataTemplate.sessions = this.template.sessions.map((x) => Object.assign({}, x))
    this.dataTemplate.capacity = this.template.capacity
  }
}
</script>

<style lang="scss">
</style>
